import { SERVICES } from 'constants/Sources';

export const getQueryParam = locationSearch => {
  if (!locationSearch) {
    return {};
  }
  return locationSearch
    .substr(1)
    .split('&')
    .reduce((queryObject, keyValuePair) => {
      const [key, value] = keyValuePair.split('=');
      queryObject[key] = value;
      return queryObject;
    }, {});
};

const getQuery = queryParams => {
  return Object.keys(queryParams)
    .filter(key => (queryParams[key] && (queryParams[key].length === undefined || queryParams[key].length > 0)) || queryParams[key] === 0)
    .map(key => `${key}=${queryParams[key] instanceof Array ? queryParams[key].join(',') : queryParams[key]}`)
    .join('&');
};

export const updatePageUrl = (newQueryParams, previousQueryParams, history, url) => {
  const queryParams = {
    ...previousQueryParams,
    ...newQueryParams
  };
  const query = getQuery(queryParams);
  history.push(`/${url}?${query}`);
};

export const exportToCsv = (queryParams, limit = null, order = null) => {
  const query =
    queryParams &&
    Object.keys(queryParams)
      .filter(key => queryParams[key] || queryParams[key] === 0)
      .map(key => `${key}=${queryParams[key]}`)
      .join('&');
  const access_token = JSON.parse(localStorage.getItem('user') || '{}').access_token;
  const limitQuery = limit ? `&limit=${limit}` : '';
  const orderQuery = order ? `&order = ${order}` : '';
  const queryWithToken = `${query}&access_token=${access_token}${limitQuery}${orderQuery}`;
  return queryWithToken;
};

export const findTitle = (item, arr) => {
  const obj = arr.find(el => el.id === item);
  if (obj) {
    return obj.category;
  } else {
    return item;
  }
};
export const findStateTitle = (item, arr) => {
  const obj = arr.find(el => el.value === item);
  if (obj) {
    return obj.title;
  } else {
    return item;
  }
};

const getStatesAndLocations = brand => {
  const stateLocations = [];
  brand.states.forEach(state => {
    if (state.title !== 'Other region' && state.title !== 'Other' && state.locations.length) {
      const stateLocation = {
        title: `${state.title}`,
        value: `state_${state.id}`,
        key: `state_${state.id}`,
        children: state.locations.map(location => ({
          title: `${location.title}-${location.locationId}`,
          key: `location_${location.id}`,
          value: `location_${location.id}`
        }))
      };
      stateLocations.push(stateLocation);
    } else {
      if ((state.title === 'Other region' || state.title === 'Other') && state.locations.length) {
        state.locations.forEach(location => {
          const locationId = {
            title: `${location.title}-${location.locationId}`,
            key: `location_${location.id}`,
            value: `location_${location.id}`
          };
          stateLocations.push(locationId);
        });
      }
    }
  });
  return stateLocations;
};
export const getLocationsTree = brandsStatesLocations => {
  const locations = [];
  brandsStatesLocations.forEach(brand => {
    if (brand.title !== 'Other brand' && brand.title !== 'Other') {
      const brandRegion = {
        title: brand.title,
        key: `brand_${brand.id}`,
        value: `brand_${brand.id}`,
        client: brand.client,
        children: getStatesAndLocations(brand)
      };
      return locations.push(brandRegion);
    } else {
      brand.states.forEach(state => {
        if (state.title !== 'Other region' && state.title !== 'Other') {
          const stateLocation = {
            title: `${state.title}`,
            value: `state_${state.id}`,
            key: `state_${state.id}`,
            children: state.locations.map(location => ({
              title: `${location.title}-${location.locationId}`,
              key: `location_${location.id}`,
              value: `location_${location.id}`
            }))
          };
          return locations.push(stateLocation);
        } else {
          state.locations.forEach(location => {
            const locationWithoutBrand = {
              title: `${location.title}-${location.locationId}`,
              key: `location_${location.id}`,
              value: `location_${location.id}`
            };
            return locations.push(locationWithoutBrand);
          });
        }
      });
    }
  });
  const filteredLocationsWithoutOther = locations.filter(locationNode => !locationNode.title.includes('Other'));
  const sortedLocationsTree = filteredLocationsWithoutOther
    .sort((a, b) => {
      if (a.title > b.title) {
        return 1;
      }
      if (a.title < b.title) {
        return -1;
      }
      return 0;
    })
    .map(element => {
      return {
        ...element,
        children:
          element.children &&
          element.children
            .sort((a, b) => {
              if (a.title > b.title) {
                return 1;
              }
              if (a.title < b.title) {
                return -1;
              }
              return 0;
            })
            .map(location => ({
              ...location,
              children:
                location.children &&
                location.children.sort((a, b) => {
                  if (a.title > b.title) {
                    return 1;
                  }
                  if (a.title < b.title) {
                    return -1;
                  }
                  return 0;
                })
            }))
      };
    });
  return sortedLocationsTree;
};

export const sortServices = services =>
  services.sort(function(a, b) {
    if (a.title > b.title) {
      return 1;
    }
    if (a.title < b.title) {
      return -1;
    }
    return 0;
  });
export const sortResources = resources =>
  resources.sort(function(a, b) {
    if (a > b) {
      return 1;
    }
    if (a < b) {
      return -1;
    }
    return 0;
  });
export const findStates = states => {
  const brandStates = [];
  states.forEach(state => brandStates.push(state.title));
  return brandStates.join(', ');
};
export const getBrandRegionArray = brands => {
  let brandRegionArr = [];
  brands.forEach(brand =>
    brand.states.forEach(state => {
      if (state.title !== 'Other region' && state.title !== 'Other') {
        let brandRegion = {
          value: state.id,
          title: `${brand.title},${state.title}`
        };
        brandRegionArr.push(brandRegion);
      }
    })
  );
  return brandRegionArr;
};
export const getBrandsArray = brands => {
  let brandsArr = [];
  brands.forEach(brand => {
    if (brand.title !== 'Other brand' && brand.title !== 'Other') {
      let brandRegion = {
        value: brand.id,
        title: `${brand.title}`
      };
      brandsArr.push(brandRegion);
    }
  });
  return brandsArr;
};

export const getLocationsList = location => {
  let profilesListArr = [];
  SERVICES.map(service => (location[service.value] && service.value !== 'hungerStation' ? profilesListArr.push(service.title) : null));
  location.additionalSources &&
    location.additionalSources.length > 0 &&
    location.additionalSources.forEach(source => profilesListArr.push(source.title));
  const profilesList = { profiles: profilesListArr.length, list: profilesListArr.join(', ') };
  return profilesList;
};

export const sortCategories = arr => arr.sort((a, b) => a.positionInList - b.positionInList);

export const generateLocationUrl = (url, systemId, brand = null, delivery = null) => {
  if (brand) {
    return `${url}brand/${systemId}`;
  }
  if (delivery) {
    return `${url}${systemId}/#delivery`;
  }
  return `${url}${systemId}`;
};

export const checkOwnerRole = () => {
  const userAsCompany = JSON.parse(localStorage.getItem('ownerAsCompany') || '{}').userName;
  const userAsClientName = JSON.parse(localStorage.getItem('ownerAsUser') || '{}').userName;
  const adminAsUser = JSON.parse(localStorage.getItem('adminAsUser') || '{}').userName;
  if (userAsClientName) {
    return userAsClientName;
  } else if (userAsCompany) {
    return userAsCompany;
  } else if (adminAsUser) {
    return adminAsUser;
  }
  return null;
};

export const updateQueryUrl = url => {
  let updatedUrl = url;

  const ownerAsCompanyId = JSON.parse(localStorage.getItem('ownerAsCompany') || '{}').id;
  const ownerAsPartnerId = JSON.parse(localStorage.getItem('ownerAsPartner') || '{}').id;

  const ownerAsUserId = JSON.parse(localStorage.getItem('ownerAsUser') || '{}').id;
  const partnerAsCompanyId = JSON.parse(localStorage.getItem('partnerAsCompany') || '{}').id;
  const partnerAsUserId = JSON.parse(localStorage.getItem('partnerAsUser') || '{}').id;

  const adminAsUserId = JSON.parse(localStorage.getItem('adminAsUser') || '{}').id;

  let splitter = url.includes('?') ? '&' : '?';

  if (ownerAsUserId) {
    updatedUrl += `${splitter}ownerAsUser=${ownerAsUserId}`;
  } else if (!ownerAsUserId && ownerAsCompanyId && !url.includes('/me') && !url.includes('/admin/companies/')) {
    updatedUrl += `${splitter}ownerAsCompany=${ownerAsCompanyId}`;
  } else if (!ownerAsUserId && !ownerAsCompanyId && ownerAsPartnerId && !partnerAsUserId && !partnerAsCompanyId) {
    updatedUrl += `${splitter}ownerAsPartner=${ownerAsPartnerId}`;
  }

  splitter = updatedUrl.includes('?') ? '&' : '?';

  if (partnerAsUserId) {
    updatedUrl += `${splitter}partnerAsUser=${partnerAsUserId}`;
  } else if (!partnerAsUserId && partnerAsCompanyId && !url.includes('/me')) {
    updatedUrl += `${splitter}partnerAsCompany=${partnerAsCompanyId}`;
  }

  if (adminAsUserId) {
    updatedUrl += `${splitter}adminAsUser=${adminAsUserId}`;
  }

  return updatedUrl;
};

export const clearLocalStoragFromUserRoles = () => {
  localStorage.removeItem('ownerAsCompany');
  localStorage.removeItem('ownerAsUser');
  localStorage.removeItem('partnerAsCompany');
  localStorage.removeItem('partnerAsUser');
  localStorage.removeItem('ownerAsPartner');
  localStorage.removeItem('adminAsUser');
};

export const isMobile = () => {
  var check = false;
  (function(a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
};

export const isEnglish = text => {
  if (text) {
    const textWithoutEmidji = text.replace(
      /[\u{1f300}-\u{1f5ff}\u{1f900}-\u{1f9ff}\u{1f600}-\u{1f64f}\u{1f680}-\u{1f6ff}\u{2600}-\u{26ff}\u{2700}-\u{27bf}\u{1f1e6}-\u{1f1ff}\u{1f191}-\u{1f251}\u{1f004}\u{1f0cf}\u{1f170}-\u{1f171}\u{1f17e}-\u{1f17f}\u{1f18e}\u{3030}\u{2b50}\u{2b55}\u{2934}-\u{2935}\u{2b05}-\u{2b07}\u{2b1b}-\u{2b1c}\u{3297}\u{3299}\u{303d}\u{00a9}\u{00ae}\u{2122}\u{23f3}\u{24c2}\u{23e9}-\u{23ef}\u{25b6}\u{23f8}-\u{23fa}]/gu,
      ''
    );
    // eslint-disable-next-line
    const englishCharacter = /^[A-Za-z0-9₴!"#$^&\-№;%:,?’“”*()_+'\/\\<>\[\]{}.\s|\s+ +]*$/;
    const result = englishCharacter.test(textWithoutEmidji) ? true : false;
    return result;
  } else {
    return 'no text';
  }
};

export const validateNoOnlySpaces = (_, value) => {
  if (!value || value.trim().length === 0) {
    return Promise.reject(new Error('The field cannot be empty or contain only spaces!'));
  }

  if (value[0] === ' ') {
    return Promise.reject(new Error('The field cannot start with a space!'));
  }
  return Promise.resolve();
};
