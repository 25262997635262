import { createResource } from 'redux-rest-resource';

import { API_URL } from '../../config';

export const { types, actions, rootReducer } = createResource({
  name: 'state',

  actions: {
    fetch: {
      method: 'GET',
      url: `${API_URL}v2/api/states`,

      reduce: (state, action) => {
        if (action.status !== 'resolved') {
          return {
            ...state,
            isFetching: true
          };
        } else {
          return { ...state, items: action.body, isFetching: false };
        }
      }
    },

    update: {
      method: 'PATCH',
      url: `${API_URL}v2/api/states/:id`,

      reduce: (state, action) => {
        if (action.status !== 'resolved') {
          return {
            ...state,
            isFetching: true
          };
        } else {
          let updatedListWithoutOverrides;
          let updatedListWithOverrides;

          if (!action.body.language) {
            updatedListWithoutOverrides = [action.body, ...state.items.statesWithoutOverrides];
            updatedListWithOverrides = state.items.statesWithOverrides.filter(item => item.id !== action.body.id);
          } else {
            updatedListWithOverrides = [action.body, ...state.items.statesWithOverrides];
            updatedListWithoutOverrides = state.items.statesWithoutOverrides.filter(item => item.id !== action.body.id);
          }

          return {
            ...state,
            items: {
              statesWithOverrides: updatedListWithOverrides,
              statesWithoutOverrides: updatedListWithoutOverrides
            },
            isFetching: false
          };
        }
      }
    }
  }
});
